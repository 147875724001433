.container-progress-gratin {
    width: 110px;
    background-color: #ffff;
    border-radius: 10px;
    margin: 0 auto;
    padding: 4px 16px;
    display: flex;
    justify-content: space-between;
    grid-gap: 3px;
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;

    .progress-stage {
        width: 20%;
        height: 10px;
        background-color: var(--bs-gray-200);
        border-radius: 4px;
    }
    .progress-active {
        background-color: var(--color-primary);
    }
}
.container-progress-payment {
    width: 80px;
    background-color: #ffff;
    border-radius: 10px;
    margin: 0 auto;
    padding: 4px 16px;
    display: flex;
    justify-content: space-between;
    grid-gap: 3px;
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;

    .progress-stage {
        width: 30%;
        height: 10px;
        background-color: var(--bs-gray-200);
        border-radius: 4px;
    }
    .progress-active {
        background-color: var(--color-primary);
    }
}
.container-register {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: 100vh;
    padding: 3rem 15vw;

    .logo {
        width: 40%;
        z-index: 99;
    }
    .register {
        width: 400px;
        border: 1px solid #dadce0;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 40px;
        box-shadow: var(--shadow-low);
        position: relative;
        text-align: center;
        background-color: #ffff;
        padding-top: 3rem;
    
        .points {
            position: fixed;
            width: 100px;
            right: 5%;
            bottom: 5%;
            opacity: 0.7;
        }
        .to-return {
            display: inline-block;
            text-align: center;
            margin-top: 10px;
            text-decoration: none;
            color: var(--color-secondary);
            transition: color .3s;
    
            &:hover {
                color: var(--color-dark-secondary);
                transition: color .3s;
            }
        }
        .emoji {
            position: absolute;
            top: -40px;
            background-color: white;
            border-radius: 50%;
            padding: 10px;
            width: 20%;

            &:hover ~ .cancel-disabled-button {
                background-color: #d3d3d3;
                transition: all 0.3s;
            }
        }
        .icon-down {
            position: absolute;
            right: 6px;
            top: 8px;
            color: black;
            cursor: pointer;
        }
        .icon-right {
            color: black;
            cursor: pointer;
        }
        .container-coupon {
            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-gap: 2px;

            .message-error {
                bottom: -34px;
            }
            .apply-coupon-void {
                font-size: 14px;
            }
            .apply-coupon {
                background-color: var(--color-primary);

                &:hover {
                    background-color: var(--color-primary-dark);
                }
            }
        }
        .sign-in {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90%;
            padding: 0 10%;
            z-index: 99;
    
            .text {
                width: 110%;
                font-size: 20px;
                margin-bottom: 22px;
            }
            .text-terms-and-conditions {
                width: 110%;
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 22px;
    
                .link-terms-and-conditions {
                    color: var(--color-light-black);
                    font-style: italic;
                }
            }
            .form {
                width: 100%;
    
                .container-input {
    
                    .input {
                        font-size: 14px;
                        text-align: center;
                        border-radius: 16px;
    
                        &::placeholder {
                            font-size: 14px;
                            text-align: center;
                        }
                    }
                    .message-error-verification-email {
                        bottom: -2rem;
                    }
                }
            }
        }
        
        .sign-in-payment {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90%;
            padding: 0 10%;
            z-index: 99;
    
            .container-plan {
                line-height: normal;
                width: 110%;
                margin-top: 2rem;

                .show-description {
                    cursor: pointer;
                    color: var(--color-primary);
                    text-align: initial;
                }
            }
            .container-title {
                line-height: normal;
                width: 110%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .price { 
                    font-size: 13px;

                    .price-discount {
                        color: var(--color-primary);
                    }
                }
                .description-plan {
                    font-size: 11px;
                }
                .name-radio {
                    display: flex;
                    align-items: center;
                    grid-gap: 6px;
                    margin-bottom: 1rem;
                    
                    .text {
                        margin: 0;
                    }
                }
                .container-description {
                    width: 100%;
                    
                    .description {
                        font-size: 12px;
    
                        p {
                            margin-top: 10px;
                            text-align: initial;
                        }
                        ul {
                            list-style: none;
                            padding: 0;
                            margin: 0;
    
                            li {
                                position: relative;
                                padding-left: 18px;
                                text-align: initial;
                                margin-bottom: 6px;
    
                                &::before {
                                    content: "";
                                    background-image: url("../../../assets/icons/check-solid.svg");
                                    background-size: cover;
                                    width: 10px;
                                    height: 10px;
                                    display: block;
                                    position: absolute;
                                    top: 3px;
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }
            .border {
                width: 80%;
                margin: 0 auto;
                border-bottom: 1px solid var(--color-light-grey);
            }
            .title-sign-in-payment {
                font-size: 13px;
            }
            .text-terms-and-conditions {
                width: 110%;
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 22px;
    
                .link-terms-and-conditions {
                    color: var(--color-light-black);
                    font-style: italic;
                }
            }
            .form {
                width: 100%;
    
                .container-input {
                    position: relative;
    
                    .input {
                        font-size: 14px;
                        text-align: center;
                        border-radius: 16px;
    
                        &::placeholder {
                            font-size: 12px;
                            text-align: center;
                        }
                    }
                    .message-error-verification-email {
                        bottom: -2rem;
                    }
                    .message-error-address {
                        bottom: -2rem;
                    }
                    .icon-popup-contract {
                        position: absolute;
                        right: -1.5rem;
                        top: 0;
                        bottom: 0;
                        margin: auto 0;
                        cursor: pointer;

                        &:hover ~ .example-invoice-epm {
                            opacity: 1;
                        }
                    }
                    .example-invoice-epm {
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto 0;
                        left: calc(100% + 1.5rem);
                        width: 300px;
                    }
                }
                .container-cedula {
                    display: grid;
                    grid-template-columns: 1fr 0fr 3fr;
                    grid-gap: 2px;
    
                    .message-error {
                        bottom: -34px;
                    }
                }
                .container-address {

                    .select-modal-cities {
                        height: 90%;
                        width: 80%;
                        left: 10%;
                        top: 5%;
                        overflow: auto;

                        &::-webkit-scrollbar {
                            width: 8px;
                        }
                        &::-webkit-scrollbar-thumb {
                            background-color: white;
                            border-radius: 10px;
                        }
                        &::-webkit-scrollbar-track {
                            background: transparent;
                        }
                    }
                }
                .container-phone {
                    // display: grid;
                    // grid-template-columns: 1fr 3fr;
    
                    .select-indicatives {
                        width: 100%;
                    }
                    .container-input {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
        
                        .input {
                            width: 100%;
    
                            &::placeholder {
                                font-size: 12px;
                            }
                            &[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button { 
                                -webkit-appearance: none; 
                                margin: 0; 
                            }
                        }
                        .message-error-number-phone {
                            position: absolute;
                            top: 110%;
                            left: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
        .upgrade {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90%;
            padding: 0 10%;
            z-index: 99;
    
            
            .container-title {
                line-height: normal;
                width: 110%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .price { 
                    font-size: 13px;

                    .price-discount {
                        color: var(--color-primary);
                    }
                }
            }
            .container-plan {
                line-height: normal;
                width: 110%;
                margin-top: 2rem;

                .show-description {
                    cursor: pointer;
                    color: var(--color-primary);
                    text-align: initial;
                }
                .price { 
                    font-size: 13px;
                    text-align: initial;

                    .price-discount {
                        color: var(--color-primary);
                    }
                }
                .description-plan {
                    font-size: 11px;
                }
                .name-radio {
                    display: flex;
                    align-items: center;
                    grid-gap: 6px;
                    margin-bottom: 1rem;
                    
                    .text {
                        margin: 0;
                    }
                }
                .container-description {
                    width: 100%;
    
                    .description {
                        font-size: 12px;
    
                        p {
                            margin-top: 10px;
                            text-align: initial;
                        }
                        ul {
                            list-style: none;
                            padding: 0;
                            margin: 0;
    
                            li {
                                position: relative;
                                padding-left: 18px;
                                text-align: initial;
                                margin-bottom: 6px;
    
                                &::before {
                                    content: "";
                                    background-image: url("../../../assets/icons/check-solid.svg");
                                    background-size: cover;
                                    width: 10px;
                                    height: 10px;
                                    display: block;
                                    position: absolute;
                                    top: 3px;
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }
            .border {
                width: 80%;
                margin: 0 auto;
                border-bottom: 1px solid var(--color-light-grey);
            }
            .title-sign-in-payment {
                font-size: 13px;
            }
            .text-terms-and-conditions {
                width: 110%;
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 22px;
    
                .link-terms-and-conditions {
                    color: var(--color-light-black);
                    font-style: italic;
                }
            }
            .form {
                width: 100%;
    
                .container-input {
    
                    .input {
                        font-size: 14px;
                        text-align: center;
                        border-radius: 16px;
    
                        &::placeholder {
                            font-size: 12px;
                            text-align: center;
                        }
                    }
                    .message-error-verification-email {
                        bottom: -2rem;
                    }
                    .message-error-address {
                        bottom: -2rem;
                    }
                }
                .container-cedula {
                    display: grid;
                    grid-template-columns: 1fr 0fr 3fr;
                    grid-gap: 2px;
    
                    .message-error {
                        bottom: -34px;
                    }
                }
                .container-address {

                    .select-modal-cities {
                        height: 90%;
                        width: 80%;
                        left: 10%;
                        top: 5%;
                        overflow: auto;

                        &::-webkit-scrollbar {
                            width: 8px;
                        }
                        &::-webkit-scrollbar-thumb {
                            background-color: white;
                            border-radius: 10px;
                        }
                        &::-webkit-scrollbar-track {
                            background: transparent;
                        }
                    }
                }
            }
        }
        .congratulations {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90%;
            padding: 0 10%;
            position: relative;

            .image-confetti {
                position: fixed;
                top: 0;
                z-index: 9;
            }
            .container-titles {
                line-height: normal;
                z-index: 99;
                
                .title {
                    font-size: 20px;
                }
            }
            .text {
                width: 80%;
                text-align: center;
                margin: 0 auto;
                line-height: normal;
                font-size: 13px;
                z-index: 99;
            }
            .text-bottom {
                font-size: 20px;
                z-index: 99;
            }
            .button-congratulations {
                z-index: 99;
            }
        }
        .form-basic-information {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 0 10%;
            padding-bottom: 1rem;
    
            .contain {
                width: 100%;
                
                .text {
                    font-size: 20px;
                    margin-bottom: 0;
                }
                .container-input {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
    
                    .message-error-favorite-name {
                        bottom: -2rem;
                    }
                    .input {
                        width: 100%;
                        
                        &::placeholder {
                            font-size: 14px;
                        }
                        &[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button { 
                            -webkit-appearance: none; 
                            margin: 0; 
                        }
                    }
                    .example-name-short {
                        font-size: 10px;
                        display: inline-block;
                        text-align: center;
                    }
                }
                .container-terms {
                    font-size: 12px;
                }
                .container-cedula {
                    display: grid;
                    grid-template-columns: 1fr 3fr;
    
                    .message-error {
                        bottom: -34px;
                    }
                }
                .container-phone {
                    // display: grid;
                    // grid-template-columns: 1fr 0fr 3fr;
                    width: 100%;
    
                    .select-indicatives {
                        width: 100%;
                        appearance: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;

                        &::-ms-expand {
                            display: none;
                        }
                    }
                    .container-input {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
        
                        .input {
                            width: 100%;
    
                            &::placeholder {
                                font-size: 14px;
                            }
                            &[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button { 
                                -webkit-appearance: none; 
                                margin: 0; 
                            }
                        }
                        .message-error-number-phone {
                            position: absolute;
                            top: 110%;
                            left: 0;
                            right: 0;
                        }
                    }
                }
            }
            .phase-2 {
                width: 100%;
                
                .container-input {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-top: 1rem;
    
                    .input {
                        width: 100%;
                    }
                }
                .container-terms {
                    font-size: 12px;
                }
            }
            .phase-3 {
                width: 100%;
                
                .container-phone {
                    display: grid;
                    grid-template-columns: 1fr 3fr;
    
                    .select-indicatives {
                        width: 100%;
                    }
                    .container-input {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
        
                        .input {
                            width: 100%;
    
                            &::placeholder {
                                font-size: 14px;
                            }
                            &[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button { 
                                -webkit-appearance: none; 
                                margin: 0; 
                            }
                        }
                        .message-error-number-phone {
                            position: absolute;
                            top: 110%;
                            left: 0;
                            right: 0;
                        }
                    }
                }
                .container-input {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
    
                    .input {
                        width: 100%;
    
                        &::placeholder {
                            font-size: 14px;
                        }
                        &[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button { 
                            -webkit-appearance: none; 
                            margin: 0; 
                        }
                    }
                }
                .switch {
                    width: 3.5rem;
                }
                .switch-alerts-notifications {
                    font-size: 12px;
                }
            }
        }
        .plan {
            width: 90%;
            background-color: white;
            border-radius: 40px;
            position: static !important;
            
            .bg-head {
                height: 45vh;
                width: 100%;
                background: linear-gradient(var(--color-primary-2),var(--color-primary));
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 40px 40px 0 0;
                z-index: 9;
            }
            .logo-greenbi-white {
                width: 80%;
                z-index: 99;
                position: relative;
                margin-bottom: 1rem;
                margin-top: 1rem;
            }
            .phase-1 {
                width: 100%;
                box-shadow: var(--shadow-low);
                background-color: white;
                border-radius: 40px;
                padding: 1rem;
                padding-top: 0;
                margin-bottom: 2rem;
                z-index: 99;
                position: relative;
                padding-top: 1rem;
    
                .prices {
                    display: grid;
                    grid-template-columns: 3fr 1.5fr;
                    grid-gap: 10px;
                    align-items: center;
                    margin-bottom: 6px;
    
                    .text {
                        text-align: end;
                        font-weight: 800;
    
                        .text-red {
                            color: var(--color-light-red);
                            text-transform: uppercase;
                        }
                    }
                    .price {
                        text-align: start;
                        display: flex;
    
                        .price-start {
                            font-size: 20px;
                            font-weight: 700;
                        }
                        .price-end {
                            font-size: 14px;
                        }
                        .price-discount {
                            font-size: 26px !important;
                            color: var(--color-primary-2);
                        }
                    }
                }
                .container-description {
                    width: 90%;
                    margin: 0 auto;
    
                    .description {
                        font-size: 12px;
    
                        ul {
                            list-style: none;
                            padding: 0;
                            margin: 0;
    
                            li {
                                position: relative;
                                padding-left: 18px;
                                text-align: start;
                                margin-bottom: 6px;
    
                                &::before {
                                    content: "";
                                    background-image: url("../../../assets/icons/check-solid.svg");
                                    background-size: cover;
                                    width: 10px;
                                    height: 10px;
                                    display: block;
                                    position: absolute;
                                    top: 3px;
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .site-information {
            width: 80%;
            padding-bottom: 1rem;

            .phase-1 {
                width: 85%;
                margin: 0 auto;
    
                .container-input {
                    display: grid !important;
                    align-content: flex-end;
                    
                    .asterisco-required {
                        position: absolute;
                        top: 0;
                        left: 100%;
                        color: var(--color-light-red);
                    }
                    .label {
                        font-size: 12px;
                    }
                    .input {
                        width: 100%;
                        cursor: pointer;
                    }
                    .input-manual {
                        width: 100%;
                    }
                    .example-name-short {
                        font-size: 10px;
                        display: inline-block;
                        text-align: center;
                    }
                    .complement {
                        font-size: 10px;
                        margin-bottom: 0;
                    }
                    .text-red {
                        color: var(--color-light-red);
                    }
                }
                .text-address {
                    font-size: 15px;
                    margin-bottom: 1rem;
                }
                .container-address {
                    display: grid;
                    grid-template-columns: 2fr 2.5fr 0fr 1fr 0fr 1fr;
                    grid-gap: 8px;
                }
            }
            .phase-3 {
                width: 100%;
    
                .border-si-phase-1 {
                    border-bottom: 2px solid var(--color-light-grey);
                }
                .title {
                    font-weight: 500;
                }
                .text {
                    font-size: 12px;
                    font-weight: 500;
                }
                .container-select-modal {
                    position: relative;
                    width: 100%;
                }
                .container-input {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
    
                    .label {
                        font-size: 12px;
                        text-align: center;
                    }
                    .input {
                        width: 100%;
                        border-radius: 20px;
                        border-top-left-radius: 20px !important;
                        border-bottom-left-radius: 20px !important;
    
                        &[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button { 
                            -webkit-appearance: none; 
                            margin: 0; 
                        }
                        &::placeholder {
                            font-size: 12px;
                        }
                    }
                    .text-informative {
                        font-size: 12px;
                    }
                }
                .button-select-modal {

                    &::first-letter {
                        text-transform: capitalize;
                    }
                }
            }
            .phase-4 {
    
                .title {
                    font-weight: 600;
                }
                .text {
                    font-size: 14px;
                }
            }
        }
        .upload-files {
            width: 85%;
            margin: 0 auto;
    
            .border-upload-files {
                border-bottom: 2px solid var(--color-light-grey);
                margin-bottom: 1rem;
            }
            .container-info-file {
                font-size: 12px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-left: 1rem;
                position: relative;

                .button-delete-file {
                    position: absolute;
                    left: 0;
                    top: 3px;
                    cursor: pointer;
                }
                .file-name {
                    color: var(--color-primary);
                    margin: 0;
                    word-break: break-all;
                    text-align: initial;
                }
                .file-size {
                    margin: 0;
                }
                .border-file {
                    width: 100%;
                    border-bottom: 1px solid var(--color-light-grey);
                    margin-bottom: 10px;
                }
                 .container-progress-file {
                    width: 100%;
                    border-radius: 20px;
                    margin-bottom: 2px;

                     .progress-file {
                         width: 0;
                         height: 4px;
                         background-color: var(--color-primary-dark);
                         border-radius: 20px;
                     }
                 }
                 .container-progress-file--bg {
                    background-color: var(--color-primary-light);
                 }
            }
            .container-dropbox {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: center;
                min-height: 100px;
                margin-bottom: 8px;

                .container-icon-file {
                    position: relative;
    
                    .icon-file {
                        font-size: 4rem;
                        margin: 20px auto;
                    }
                    .icon-delete {
                        position: absolute;
                        top: 10%;
                        cursor: pointer;
                        z-index: 99;
                    }
                }
                .label-multiple-files {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    min-height: 100%;
                    border: 3px solid var(--color-light-grey);
                    border-radius: 20px;
                    margin-bottom: 1rem;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: .5;
                }
                .image {
                    color: var(--color-primary);
                }
                .dropbox-disabled {
                    cursor: default;
                }
            }
            .text {
                font-size: 12px;
            }
            .button-no-bills {
                margin-top: 10px;
                background-color: #a3a3a3;

                &:hover {
                    background-color: #7a7c87;
                }
            }
            .button-no-bills-disabled {
                margin-top: 10px;
                background-color: #a3a3a3;

                &:hover {
                    background-color: #a3a3a3;
                }
            }
            .to-return {
                margin-bottom: 10px;
            }
            .container-alert-upload-files-phase-1 {
                width: 35%;
            }
        }
        .container-emoji {
            position: absolute;
            top: -40px;
            padding: 0px 14px;
            border-radius: 50%;
            background-color: white;
            width: 25%;

            .emoji-upload-files {
                width: 100%;
            }
        }
        .select-search {
      
            &::first-letter {
                text-transform: capitalize;
            }
            .text-selected-default {
                color: red;
            }
        }
    }
}

@media (max-width: 1024px) {
    .container-register {
        padding: 3rem 5vh;

        .register {
    
            .upload-files {
    
                .container-alert-upload-files-phase-1 {
                    width: 44%;
                    height: 21%;
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .container-register {
        padding: 3rem 2rem;

        .register {
            width: 350px;
    
            .upload-files {
    
                .container-alert-upload-files-phase-1 {
                    width: 55%;
                    height: 27%;
                }
            }
        }
    }
    .swal-modal-success-or-error {
        width: auto;
        padding: 0 4rem;
    }
}

@media (max-width: 800px) {
    .container-register {
        padding: 3rem 2rem;
        padding-top: 10vh;
        flex-direction: column;
        justify-content: flex-start;
        grid-gap: 4rem;
        padding-bottom: 20vh;

        .logo {
            width: 200px;
        }
        .register {
    
            .points {
                width: 70px;
            }
            .emoji {
                width: 25%;
            }
            .sign-in {
                width: 100%;

                .text-terms-and-conditions {
                    font-size: 10px;
                }
            }
            .sign-in-payment {
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;

                .container-title {

                    .container-description {
                        
                        .description {
                            padding: 0 1.5rem;
                        }
                    }
                    .text {
                        text-align: initial !important;
                    }
                }
                .text-terms-and-conditions {
                    font-size: 10px;
                }
            }
            .upload-files {
    
                .container-alert-upload-files-phase-1 {
                    width: 55%;
                    height: 27%;
                }
            }
        }
    }
    .swal-modal-success-or-error {
        width: auto;
        padding: 0 4rem;
    }
}

@media (max-width: 700px) {
    .container-register {
        
        .register {
            max-width: 90%;
            padding-left: 4rem;
            padding-right: 4rem;
        }
    }
}

@media (max-width: 600px) {
    .container-register {

        .register {
            max-width: 90%;
            padding-left: 2rem;
            padding-right: 2rem;
    
            .form-first-steps {
        
                .image-primary-register {
                    display: none;
                }
            }
            .upload-files {
    
                .container-alert-upload-files-phase-1 {
                    width: 80%;
                    height: 38%;
                    padding: 0 8px;
                }
            }
            .container-emoji {
                width: 30%;
            }
        }
    }
}

@media (max-width: 500px) {
    .container-register {

        .register {
            max-width: 90%;
            padding-left: 1rem;
            padding-right: 1rem;
    
            .sign-in-payment {
    
                .container-phone {
    
                    .select-indicatives {
                        background-image: none;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .container-register {

        .register {
            max-width: 90%;
    
            .upload-files {
    
                .container-alert-upload-files-phase-1 {
                    width: 80%;
                    height: 50%;
                    padding: 0 8px;
                }
            }
        }
    }
}

@media (max-width: 350px) {
    .container-register {
        
        .register {
            max-width: 90%;
            padding-left: 0;
            padding-right: 0;
    
            .upload-files {
    
                .container-alert-upload-files-phase-1 {
                    width: 80%;
                    height: 50%;
                    padding: 0 8px;
                }
            }
        }
    }
}

