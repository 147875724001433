.log-in-social {
    height: 2.5rem;
    display: grid;
    grid-template-columns: 2fr 8fr;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    border: none;
    border-radius: 8px;
    border-color: rgba(0, 0, 0, .2);
    outline: none;
    padding: 0 1rem;
    text-align: start;

    .container-icon-social {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .icon-social {
            height: 28px;
            padding-right: 6px;
            margin-right: 6px;
        }
    }
}