.app-provider-container {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  
    .over-container {
      width: 100vw;
      height: 100vh;
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
      background-color: rgba($color: #000000, $alpha: 0.5);
    }
  }