.button-atomic {
    padding: 5px 0;
    background-color: var(--color-primary);
    border: none;
    color: #fff;
    font-weight: 500;
    border-radius: 16px;
    padding: 4px 20px;
    transition: all .3s;

    &:hover {
        background-color: var(--color-primary-dark);
        transition: all .3s;
    }
    &:disabled {
        background-color: var(--color-primary-light);
    }
}
.button-atomic-disabled {
    background-color: var(--color-primary-light);
}
.button-normal {
    background-color: var(--color-primary);
    transition: all .3s;
}
.cancel-disabled-button {
    background-color: var(--color-dark-white);
    transition: all .3s;
    color: black;

    &:hover {
        background-color: #d3d3d3;
        transition: all .3s;
    }
}
.cancel-disabled-button--bg {
    background-color: var(--color-dark-white);
    transition: all .3s;
    color: black;

    &:hover {
        background-color: #d3d3d3;
        transition: all .3s;
    }
    &:disabled {
        background-color: var(--color-dark-white) !important;
        border: 1px solid grey;
    }
}
.button-especial {  
    background-color: var(--color-dark-white) !important;
    // border: 1px solid white !important;
    color: grey;
}
.border-button {
    // color: grey;
}