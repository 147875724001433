.background-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
}
.login {
    position: relative;
    width: 30%;
    margin: 0 auto;
    margin-top: 2rem;
    background: linear-gradient(to bottom, #d3d3d3, #f7f8f8);
    border: 5px solid #cbcfce;
    border-radius: 30px;
    padding: 20px 20px 20px;
    box-shadow: -1em 1em 10px rgb(0 0 0 / 15%);
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
        margin: 0 auto;
        width: 70%;
    }
    .socials {
        display: flex;
        flex-direction: column;
        grid-gap: 4px;
        width: 100%;

        .btn-facebook {
            background-color: #3b5998;
            color: var(--bs-white);
    
            &:hover {
                background-color: #2d4373;
            }
        }
        .btn-google {
            background-color: var(--bs-white);
            
            &:hover {
                background-color: #dbdada;
            }
        }
    }
    .border {
        width: 100%;
        border: 0 !important;
        border-top: 2px solid var(--bs-white) !important;
    }
    .title {
        color: var(--bs-gray-600);
        margin-bottom: 1rem;
        font-weight: normal;
    }
}