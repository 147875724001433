.modal-open {
    display: block !important;
    opacity: 1 !important;
    padding-top: 15vh;

    .btn-color-primary {
        background-color: var(--color-secondary) !important;
        color: white;
        transition: all .3s;

        &:hover {
            background-color: var(--color-dark-secondary) !important;
            color: white;
            transition: all .3s;
        }
    }
}