.container-radio-personality {
    display: flex;
    align-items: center;
    
    .icon-radio-personality {
        margin-right: 5px;
    }
    .label-radio-personality {
        margin-right: 5px;
    }
    .contain-input-radio-personality {
        position: relative;
        height: 20px;
        width: 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:hover .input-primary ~ .radio-container-primary {
            border: 3px solid var(--color-primary);
        }
        &:hover .input-grey ~ .radio-container-grey {
            border: 3px solid var(--color-light-grey);
        }
        &:hover .input-disabled ~ .radio-container-disabled {
            border: 2px solid var(--color-light-grey);
        }
        .input-primary {

            &:checked ~ .radio .radio-primary {
                background-color: var(--color-primary);
                border: none;
            }
        }
        .input-grey {

            &:checked ~ .radio .radio-grey {
                background-color: var(--color-light-grey);
                border: none;
            }
        }
        .input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            top: 10%;
            left: 0;
            height: 90%;
            width: 90%;
            z-index: 999;
        }
        .input-disabled {
            cursor: initial;
        }
        .radio {
            position: absolute;
            top: 10%;
            left: 0;
            height: 90%;
            width: 90%;
            background-color: #eee;
            border-radius: 50%;
            padding: 2px;
            z-index: 998;

            .contain-radio {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .radio-container-primary {
            border: 2px solid var(--color-primary);
        }
        .radio-container-grey {
            border: 2px solid var(--color-light-grey);
        }
        .radio-container-disabled {
            border: 2px solid var(--color-light-grey);
        }
        .disabled-non-pointer {
            cursor: default;
        }
    }
}