:root {
  --color-white: #fff;
  --color-dark-white: #ebebed;
  --shadow-low: 1px 2px 7px 0 rgb(0, 0, 0, 24%);
  --shadow-input: 0 0 0 0.25rem rgb(255, 67, 66, 0.4);
  --color-primary: #75ce7b;
  --color-primary-light: #98eb9e;
  --color-primary-dark: #0b834d;
  --color-primary-2: #17b692;
  --color-secondary: #567edb;
  --color-dark-secondary: #4a6cbb;
  --color-secondary-disabled: #567edba6;
  --color-light-black: #515564;
  --color-purpure: #acb7f0;
  --color-purple: #9b8acf;
  --color-dark-purple: #897ab7;
  --color-light-purple: #c8b5ff;
  --color-light-grey: #d1d1d1;
  --color-light-red: #ff4342;
}

body {
  font-family: Montserrat,'Helvetica Neue',Helvetica,Arial,sans-serif;
  color: var(--color-light-black);
  font-weight: 500;
  overflow-x: hidden;
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(233,236,239,1) 20%, rgba(204,205,207,1) 55%);

  .sc-7dvmpp-1 {
    display: none;
  }
  .form-control:focus {
    border-color: var(--color-secondary);
  }
  .input-required {
    box-shadow: var(--shadow-input);
  }
  .message-error {
    font-size: 10px;
    color: var(--color-light-red);
    position: absolute;
    bottom: -1rem;
    left: 0;
    right: 0;
  }
  .form-check-input {

    &:checked {
      background-color: var(--color-secondary);
      border-color: var(--color-secondary);
    }
  }
  .no-seleccionable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
.body-green {
  background: var(--color-primary);
}

.dxiAcZ {
  background: var(--color-primary) !important;
}

// .fXBuHm {
//   color: white !important;
// }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
