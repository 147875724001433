.bg-select-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 99;
}
.select-modal {
    width: 100%;
    position: absolute;
    left: 0;
    background-color: var(--color-dark-white);
    padding: 1rem 0;
    border-radius: 15px;
    z-index: 999;

    .list { 
        width: 80%;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .option {
            width: 100%;
            text-align: center;
            color: black;
            padding-bottom: 3px;
            text-transform: lowercase;
            border-radius: 20px;
            cursor: pointer;
            transition: all 1s;

            &::first-letter {
                text-transform: uppercase;
            }
            &:hover { 
                background-color: #d3d3d3;
                transition: all 1s;
            }
        }
        .option-without-capitalize {
            width: 80%;
            text-align: center;
            color: black;
            padding-bottom: 3px;
            border-radius: 20px;
            cursor: pointer;
            transition: all 1s;

            &:hover { 
                background-color: #d3d3d3;
                transition: all 1s;
            }
        }
        .option-overflow {
            height: 100%;
            overflow: auto;
        }
        .border-bottom-option {
            width: 75%;
            border-bottom: 1px solid #f1ebeb;
            margin-bottom: 3px;
        }
        .last-option {
            border-bottom: none;
        }
    }
}