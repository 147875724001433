.atom-input {
    width: 100%;
    padding: 10px 12px;
    border-radius: 20px;
    outline: none;
    border: 1px solid #d2d6de;
    transition: border 0.7s;
    font-size: 14px;

    &:focus {
        border-color: #3c8dbc;
        transition: border 0.7s;
    }
}