.swal-modal-success-or-error {
    width: 20%;

    .swal-text {
        text-align: center;
    }
}
.container-alert-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow-y: auto;
    background-color: rgba(0,0,0,.4);
    z-index: 10000;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s;

    .contain-alert-message {
        opacity: 1;
        pointer-events: auto;
        box-sizing: border-box;
        -webkit-animation: showSweetAlert .3s;
        animation: showSweetAlert .3s;
        will-change: transform;
        align-items: center;
        position: relative;

        .button-info-alert {
            width: auto;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .container-title {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            height: 2rem;
            margin-bottom: 2rem;
            background-color: var(--color-red-cancel);

            .icon-arrow-security {
                // position: absolute;
                height: 100%;
                top: 0;
            }
            .icon-arrow-security--left {
                left: 0;
            }
            .icon-arrow-security--right {
                right: 0;
            }
            .title {
                color: var(--color-white);
                margin-top: 2rem;
            }
        }
        .icon {
            font-size: 4rem;
            color: var(--color-primary);
        }
        .text {
            margin-top: 1rem;
            font-size: 14px;
            margin-bottom: 0;
        }
        .container-buttons-confirm {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            margin-top: 24px;

            .button {
                font-weight: bold;
            }
            .button-confirm {
                background-color: var(--color-red-cancel);
                color: var(--color-white);
                border-color: var(--color-red-cancel);
            }
            .button-cancel {
                background-color: var(--color-dark-white);
                border-color: var(--color-dark-white);
            }
        }
    }
}
.container-alert-message--show-modal {
    opacity: 1;
    pointer-events: auto;
}
.contain-alert-message {
    width: 30%;
    padding: 0 2rem;
    padding-bottom: 1rem;
    opacity: 0;
    pointer-events: none;
    background-color: #fff;
    border-radius: 30px;
    position: static;
    display: flex;
    flex-direction: column;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    z-index: 10001;
    transition: opacity .2s,-webkit-transform .3s;
    transition: transform .3s,opacity .2s;
    transition: transform .3s,opacity .2s,-webkit-transform .3s;
}

@media (max-width: 800px) {
    .contain-alert-message {
        width: auto;
        margin: 0 10vw;
    }
}