@media (max-width: 800px) {
    .container-register-congratulations {
        padding: 3rem 2rem;
        padding-top: 10vh;
        flex-direction: column;
        justify-content: flex-start;
        grid-gap: 4rem !important;
        padding-bottom: 20vh;
        width: 100%;

        .logo {
            width: 200px;
        }
        .register {
            background-color: transparent !important;
            box-shadow: none !important;
            border: none !important;
            width: 100% !important;

            .congratulations {
                padding: 0 !important;
                width: 100% !important;
                color: white;

                .button-submit {
                    background-color: white;
                    color: var(--color-primary);
                }
                .container-titles {
                    margin-bottom: 2.5rem !important;
                    
                    .title {
                        font-size: 28px !important;
                    }
                }
                .text {
                    width: 100% !important;
                    font-size: 1rem !important;
                }
                .text-bottom {
                    font-size: 28px !important;
                }
                .button-submit {
                    background-color: white;
                    color: var(--color-primary);
                    font-size: 20px;
                }
            }
        }
    }
}